<template>
  <div class="min-w-20 max-w-90">
    <loader :is-loading="isLoading" size="20" />
    <div class="w-auto">
      <div class="flex space-x-2">
        <button
          v-on-click-outside="closeMenu"
          data-cy="status-button"
          type="button"
          class="flex items-center relative pl-2 py-0-5 text-left text-xxs rounded-full bg-gray-100 whitespace-nowrap"
          :style="
            colorIndex != null
              ? [backgroundStyle, textStyle]
              : { backgroundColor: '#868D9C1A' }
          "
          @click.stop="toggleMenu"
        >
          {{ status }}
          <span
            class="ml-2 inset-y-0 flex place-self-center pr-2 cursor-pointer"
            :style="colorIndex !== null ? textStyle : { color: '#868d9c' }"
          >
            <icon-base height="6" width="10" icon="dropdown-arrow" />
          </span>
        </button>
        <div
          v-if="showDeclineReasonEditButton || model.declined_reason"
          data-cy="decline-reason-edit-button"
          type="button"
          @click.stop="showDeclineReasonModal = true"
        >
          <span
            v-if="model.declined_reason"
            v-tooltip="$t('DEALS.PLACEMENT.DECLINE_REASON_EDIT_TOOLTIP')"
          >
            <icon-base
              class="place-self-center"
              :icon="IconEdit"
              :icon-color="UTIL_COLORS.DEFAULT"
            />
          </span>
          <span
            v-else
            class="w-32 h-7 border ml-2 rounded flex justify-center items-center"
          >
            <span class="text-gray-500 text-xs">
              {{ $t("COMMON.SET") }} {{ $t("COMMON.REASON") }}
            </span>
          </span>
        </div>
      </div>

      <ul
        v-if="showMenu"
        ref="statusMenuEl"
        data-cy="status-menu"
        tabindex="-1"
        class="z-50 absolute min-w-max h-auto overflow-y-scroll overflow-x-hidden sm:w-40 bg-white shadow-lg rounded-md py-2 text-sm text-gray-500 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <li
          v-for="(status, key) in offerStatuses"
          :key="key"
          class="py-2 pr-2 hover:bg-gray-200 flex justify-start overflow-auto"
          @click.stop="updateOffersStatus(Number(key))"
        >
          <span class="ml-3 font-normal cursor-pointer">{{ status }}</span>
        </li>
      </ul>
    </div>

    <lf-modal
      v-if="showDeclineReasonModal"
      :close="cancelDeclineReason"
      :title="$t('OFFERS.DECLINE_REASON_MODAL_TITLE')"
    >
      <template #content>
        <div
          class="flex flex-col justify-center items-center px-5 border-t pt-6"
        >
          <div class="w-full mb-6 space-y-5">
            <lf-dropdown
              class="h-10"
              name="declineReasonDropdown"
              data-cy="decline-reason-dropdown"
              :placeholder="$t('DEALS.PLACEMENT.DECLINE_REASON_PLACEHOLDER')"
              hide-placeholder-when-value
              :options="declineReasonsOptions"
              :value="declineReason"
              @change="updateDeclineReason"
            />
            <lf-textarea
              v-if="
                declineReason && !declineReasons.defined.includes(declineReason)
              "
              class="text-sm px-4"
              :placeholder="
                $t('DEALS.PLACEMENT.DECLINE_DESCRIPTION_PLACEHOLDER')
              "
              :value="declineReasonOther"
              rows="6"
              name="declineReason"
              noresize
              :character-limit="TEXT_CHARACTER_LIMIT_MEDIUM"
              @change="declineReasonOther = $event.target.value"
            />
          </div>
          <div
            class="flex justify-end items-center min-w-full border-t py-6 pr-5"
          >
            <outline-button :disabled="isLoading" @click="cancelDeclineReason">
              {{ $t("COMMON.CANCEL") }}
            </outline-button>
            <primary-button
              class="ml-4 px-8"
              :disabled="isLoading"
              @click="
                updateOffersStatus(props.modelValue, offerStatusUpdateReason)
              "
            >
              {{ $t("COMMON.SAVE") }}
            </primary-button>
          </div>
        </div>
      </template>
    </lf-modal>
  </div>
</template>
<script setup lang="ts">
import { onMounted, type PropType } from "vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useTeleportV2 } from "@/hooks/elements";
import {
  COLORS,
  OFFER_STATUS_DECLINED,
  OFFER_STATUS_DECLINED_IN_CLOSING,
  TEXT_CHARACTER_LIMIT_MEDIUM,
  UTIL_COLORS
} from "@/helpers/constants";
import LfModal from "@/components/ui/Modal.vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import LfDropdown from "@/components/ui/inputs/LfDropdown.vue";
import LfTextarea from "@/components/ui/inputs/LfTextarea.vue";
import OutlineButton from "@/components/ui/buttons/OutlineButton.vue";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import { useActiveWorkflowTemplate } from "@/hooks/workflow";
import type { IOffer } from "@/models/funders";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ""
  },
  model: {
    type: Object as PropType<Partial<IOffer>>,
    required: true
  },
  value: {
    type: [String, Number],
    required: true
  }
});

const store = useStore();
const { activeTemplate } = useActiveWorkflowTemplate();

const showMenu = ref(false);
const isLoading = ref(false);
const statusMenuEl = ref<HTMLUListElement | null>(null);
const declineReason = ref<string>(props.model.declined_reason ?? "");
const declineReasonOther = ref<string>(props.model.declined_reason ?? "");
const showDeclineReasonModal = ref(false);

useTeleportV2({
  target: statusMenuEl,
  checkForOverflow: true,
  to: "#menus",
  offset: { top: 25 }
});

const COLOR_CODES = {
  1: 0,
  2: 5,
  3: 2,
  4: 5,
  5: 2,
  6: 5,
  blank: null
};

const offerStatuses = computed<Record<string, string>>(
  () => store.getters["options/offerStatuses"]
);

const status = computed(() =>
  props.modelValue ? offerStatuses.value[props.modelValue] : "--"
);

const colorIndex = computed(
  () => COLOR_CODES[(props.model.status as keyof typeof COLOR_CODES) ?? "blank"]
);

const declineReasons = computed(() => {
  return {
    defined: activeTemplate.value?.offer_declined_reasons ?? [],
    other: activeTemplate.value?.offer_declined_reasons_other_label ?? ""
  };
});

const declineReasonsOptions = computed(() =>
  declineReasons.value.defined
    .concat(declineReasons.value.other)
    .filter(Boolean)
);

const showDeclineReasonEditButton = computed(() => {
  return (
    !isLoading.value &&
    [OFFER_STATUS_DECLINED, OFFER_STATUS_DECLINED_IN_CLOSING].includes(
      Number(props.value)
    ) &&
    !props.model.declined_reason
  );
});

const offerStatusUpdateReason = computed(() => {
  return !!declineReasons.value.other &&
    declineReason.value === declineReasons.value.other
    ? declineReasonOther.value
    : declineReason.value;
});

const updateDeclineReason = (value: string | number) => {
  const reason = declineReasonsOptions.value[Number(value)];
  const isOtherSelected =
    reason === declineReasons.value.other ||
    (!reason && !!props.model?.declined_reason);

  declineReason.value = isOtherSelected
    ? declineReasons.value.other
    : (reason ?? "");

  declineReasonOther.value =
    isOtherSelected &&
    !declineReasons.value.defined.includes(props.model?.declined_reason ?? "")
      ? (props.model?.declined_reason ?? "")
      : "";
};

const cancelDeclineReason = () => {
  if (isLoading.value) {
    return;
  }
  updateDeclineReason(
    declineReasons.value.defined
      .concat(declineReasons.value.other)
      .findIndex((reason) => reason === props.model.declined_reason)
  );
  if (showDeclineReasonModal.value) {
    showDeclineReasonModal.value = false;
  }
};

const getStatusColor = (alpha = 1) =>
  colorIndex.value !== null &&
  Object.keys(COLORS).includes(`${colorIndex.value}`)
    ? `rgba(${COLORS[colorIndex.value]}, ${alpha})`
    : "";

const updateOffersStatus = async (status: string | number, reason?: string) => {
  try {
    isLoading.value = true;
    const { id } = props.model;

    await store.dispatch("applications/updateOfferStatus", {
      id,
      status,
      reason
    });
  } finally {
    if (showDeclineReasonModal.value) {
      showDeclineReasonModal.value = false;
    }
    isLoading.value = false;
  }
};

const closeMenu = () => {
  showMenu.value = false;
};

const toggleMenu = () => {
  if (!showMenu.value) {
    document.body.click();
  }
  showMenu.value = !showMenu.value;
};

const backgroundStyle = computed(() => ({
  backgroundColor: getStatusColor(0.1),
  borderStyle: "none",
  color: getStatusColor()
}));

const textStyle = computed(() => ({
  color: getStatusColor()
}));

onMounted(cancelDeclineReason);
</script>
